.react-tel-input .form-control {
    border: 0;
    font-size: 0.875rem;
    line-height: 1.25rem;
    height: 100%;
    width: 100%;
}

.react-tel-input .flag-dropdown {
    font-size: 0.875rem;
    left: 0;
}