@media (min-width: 1281px) {
    .loading-icon {
        position: absolute;
        top: calc(10% - 10px);
        left: calc(40% - 10px);
    }

    .loading {
        width: 200px;
        height: 200px;
    }
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
    .loading-icon {
        position: absolute;
        top: calc(10% - 10px);
        left: calc(40% - 10px);
    }

    .loading {
        width: 200px;
        height: 200px;
    }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
    .loading-icon {
        position: absolute;
        top: calc(10% - 10px);
        left: calc(40% - 10px);
    }

    .loading {
        width: 200px;
        height: 200px;
    }
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .loading-icon {
        position: absolute;
        top: calc(15% - 10px);
        left: calc(40% - 10px);
    }

    .loading {
        width: 200px;
        height: 200px;
    }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
    .loading-icon {
        position: absolute;
        top: calc(10% - 10px);
        left: calc(35% - 10px);
    }

    .loading {
        width: 200px;
        height: 200px;
    }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
    .loading-icon {
        position: absolute;
        top: calc(30% - 10px);
        left: calc(30% - 10px);
    }

    .loading {
        width: 200px;
        height: 200px;
    }
}
