.risiko-guardian-bg {
    background-color: #D61920 !important;
}

.text-risiko-guardian-bg {
    --tw-text-opacity: 1;
    color: rgba(214, 25, 32, var(--tw-text-opacity));
}

.text-danger{
    color: red;
}

h1 {
    margin-top: 2rem;
}

@media only screen and (max-width: 768px) {
    .sm\:p-2 {
        padding: 0.5rem;
    }

    .sm\:p-3 {
        padding: 0.75rem;
    }

    .sm\:p-4 {
        padding: 1rem;
    }

    .sm\:p-5 {
        padding: 1.25rem;
    }

    .sm\:p-8 {
        padding: 2rem;
    }

    .sm\:px-0 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .sm\:py-1 {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }

    .sm\:py-2 {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .sm\:px-2 {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .sm\:py-3 {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }

    .sm\:px-3 {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }

    .sm\:py-4 {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .sm\:px-4 {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .sm\:py-5 {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }

    .sm\:py-6 {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .sm\:px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .sm\:py-10 {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }

    .sm\:px-12 {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .sm\:py-16 {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    .sm\:py-20 {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    .sm\:py-24 {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }

    .sm\:py-40 {
        padding-top: 10rem;
        padding-bottom: 10rem;
    }

    .sm\:pt-0 {
        padding-top: 0px;
    }

    .sm\:pb-0 {
        padding-bottom: 0px;
    }

    .sm\:pt-1 {
        padding-top: 0.25rem;
    }

    .sm\:pt-2 {
        padding-top: 0.5rem;
    }

    .sm\:pb-2 {
        padding-bottom: 0.5rem;
    }

    .sm\:pl-3 {
        padding-left: 0.75rem;
    }

    .sm\:pr-4 {
        padding-right: 1rem;
    }

    .sm\:pb-4 {
        padding-bottom: 1rem;
    }

    .sm\:pl-4 {
        padding-left: 1rem;
    }

    .sm\:pt-6 {
        padding-top: 1.5rem;
    }

    .sm\:pb-6 {
        padding-bottom: 1.5rem;
    }

    .sm\:pt-8 {
        padding-top: 2rem;
    }

    .sm\:pl-10 {
        padding-left: 2.5rem;
    }

    .sm\:pt-12 {
        padding-top: 3rem;
    }

    .sm\:pr-12 {
        padding-right: 3rem;
    }

    .sm\:pt-16 {
        padding-top: 4rem;
    }

    .sm\:pb-16 {
        padding-bottom: 4rem;
    }

    .sm\:pt-20 {
        padding-top: 5rem;
    }

    .sm\:pb-20 {
        padding-bottom: 5rem;
    }

    .sm\:pt-24 {
        padding-top: 6rem;
    }

    .sm\:pt-32 {
        padding-top: 8rem;
    }

    .sm\:pb-32 {
        padding-bottom: 8rem;
    }

    .sm\:pb-40 {
        padding-bottom: 10rem;
    }

    .sm\:pt-48 {
        padding-top: 12rem;
    }

    .sm\:pb-48 {
        padding-bottom: 12rem;
    }

    .sm\:pb-64 {
        padding-bottom: 16rem;
    }

    .mt-48 {
        margin: 0;
    }

    .pr-12 {
        padding: 0;
    }

    .h-screen {
        height: 70vh;
    }

    .hide-on-mobile {
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .mt-48 {
        margin: 0;
    }

    .pr-12 {
        padding: 0;
    }

    .h-screen {
        height: 45vh;
    }
}

@media only screen and (min-width: 1024px) {

    .lg\:p-2 {
        padding: 0.5rem;
    }

    .lg\:p-3 {
        padding: 0.75rem;
    }

    .lg\:p-4 {
        padding: 1rem;
    }

    .lg\:p-5 {
        padding: 1.25rem;
    }

    .lg\:p-8 {
        padding: 2rem;
    }

    .lg\:px-0 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .lg\:py-1 {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }

    .lg\:py-2 {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .lg\:px-2 {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .lg\:py-3 {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }

    .lg\:px-3 {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }

    .lg\:py-4 {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .lg\:px-4 {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .lg\:py-5 {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }

    .lg\:py-6 {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .lg\:px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .lg\:py-10 {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }

    .lg\:px-12 {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .lg\:py-16 {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    .lg\:py-20 {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    .lg\:py-24 {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }

    .lg\:py-40 {
        padding-top: 10rem;
        padding-bottom: 10rem;
    }

    .lg\:pt-0 {
        padding-top: 0px;
    }

    .lg\:pb-0 {
        padding-bottom: 0px;
    }

    .lg\:pt-1 {
        padding-top: 0.25rem;
    }

    .lg\:pt-2 {
        padding-top: 0.5rem;
    }

    .lg\:pb-2 {
        padding-bottom: 0.5rem;
    }

    .lg\:pl-3 {
        padding-left: 0.75rem;
    }

    .lg\:pr-4 {
        padding-right: 1rem;
    }

    .lg\:pb-4 {
        padding-bottom: 1rem;
    }

    .lg\:pl-4 {
        padding-left: 1rem;
    }

    .lg\:pt-6 {
        padding-top: 1.5rem;
    }

    .lg\:pb-6 {
        padding-bottom: 1.5rem;
    }

    .lg\:pt-8 {
        padding-top: 2rem;
    }

    .lg\:pl-10 {
        padding-left: 2.5rem;
    }

    .lg\:pt-12 {
        padding-top: 3rem;
    }

    .lg\:pr-12 {
        padding-right: 3rem;
    }

    .lg\:pt-16 {
        padding-top: 4rem;
    }

    .lg\:pb-16 {
        padding-bottom: 4rem;
    }

    .lg\:pt-20 {
        padding-top: 5rem;
    }

    .lg\:pb-20 {
        padding-bottom: 5rem;
    }

    .lg\:pt-24 {
        padding-top: 6rem;
    }

    .lg\:pt-32 {
        padding-top: 8rem;
    }

    .lg\:pb-32 {
        padding-bottom: 8rem;
    }

    .lg\:pb-40 {
        padding-bottom: 10rem;
    }

    .lg\:pt-48 {
        padding-top: 12rem;
    }

    .lg\:pb-48 {
        padding-bottom: 12rem;
    }

    .lg\:pb-64 {
        padding-bottom: 16rem;
    }
}